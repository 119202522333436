<template>
	<div class="flex-col page">
		<div class="flex-col group_1">
			<div class="text-wrapper flex-col">
				<!-- <span class="text_1">登录账号</span>
				<span style="">测试</span> -->
				<div class="text_1">登录账号</div>
				<div class="text_1 text_111">智梯科技电梯维保系统</div>
			</div>
			<div class="box_1">
				<span class="text_2">请输入登录账号</span>
				<div class="flex-row section_2">
					<img src="../assets/user.png" class="image_4" />
					<input
						type="text"
						class="text_3"
						v-model="phone"
						placeholder="请输入用户名"
					/>
				</div>
				<div class="flex-row section_3">
					<img src="../assets/suo.png" class="image_4" />
					<input
						class="text_4"
						:type="flag != true ? 'password' : 'text'"
						v-model="password"
						placeholder="请输入密码"
					/>
					<img
						:src="
							flag != true
								? require('@/assets/login/hidePassword.png')
								: require('@/assets/login/showPassword.png')
						"
						class="image_4"
						@click="change"
					/>
				</div>

				<span class="text_5" @click="forget()">忘记密码？</span>
				<div
					:class="['flex-col', 'items-center', 'button', { button_1: isLogin }]"
					@click="login(isLogin)"
				>
					<span>登录</span>
				</div>
				<span class="text_7" @click="applys()">我要成为维保人</span>
				<div class="text_8"><a href="https://beian.miit.gov.cn/" target="_blank" class="routerlink">辽ICP备2022007687号</a></div>
			</div>
			
		</div>
	
	</div>
</template>

<script>
import { login } from "@/api/Login/Login";
import Vue from "vue";
import { Toast } from "vant";
export default {
	data() {
		return {
			phone: "",
			password: "",
			flag: false,
		};
	},
	computed: {
		isLogin() {
			let boo = false;
			if (this.phone && this.password) {
				boo = true;
			}
			return boo;
		},
	},
	mounted() {
		if (localStorage.getItem("token")) {
			let type = localStorage.getItem("user_type");
			this.$router.push("/" + type + "/index");
		}
	},
	name: "HelloWorld",
	props: {
		msg: String,
	},
	methods: {
		change() {
			this.flag = !this.flag;
		},
		login(lgn) {
			if (lgn) {
				login({ phone: this.phone, password: this.password }).then((res) => {
					localStorage.setItem("token", res.data.Data.token);
					localStorage.setItem("user_type", res.data.Data.type);
					if (res.data.Result !== 200) {
						Toast("用户名或密码错误");
					} else {
						// 登陆成功之后，这是跳转到首页
						if (res.data.Result === 200) {
							Toast("登陆成功");
							this.$router.push("/" + res.data.Data.type + "/index");
						}
					}
				});
			}
		},
		forget() {
			this.$router.push("/forget");
		},
		applys() {
			this.$router.push("/apply");
		},
	},
};
Vue.use(Toast);
</script>

<style scoped>
@import "../assets/common.css";
.image_4 {
	width: 1.5rem;
	height: 1.5rem;
}
.page {
	width: 100%;
	overflow: hidden;
}
.header {
	padding-top: 0.063rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	line-height: 1rem;
	white-space: nowrap;
	height: 2.81rem;
}
.group_1 {
	flex: 1 1 auto;
	overflow-y: auto;
}
.section_1 {
	padding: 0.88rem 0.81rem;
	background-color: rgb(237, 237, 237);
}
.box_1 {
	overflow-x: hidden;
	margin-top: -1.5rem;
	background-color: #f5f5f5;
	border-radius: 1.2rem 1.2rem 0 0;
	padding-top: 2.5rem;
}
.section_2,
.section_3 {
	margin: 1.56rem 1.25rem 0;
	padding: 1rem 0.88rem 0.94rem;
	color: rgb(153, 153, 153);
	font-size: 0.88rem;
	line-height: 0.88rem;
	white-space: nowrap;
	background: #fff;
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.button {
	margin: 1.5rem 1.25rem 1rem;
	padding: 1.13rem 0 1rem;
	color: rgb(0, 0, 0);
	font-size: 1rem;
	font-weight: 500;
	line-height: 1rem;
	white-space: nowrap;
	border-radius: 0.3rem;
	background: #e2e2e2;
	background-repeat: no-repeat;
}
.button_1 {
	background-color: #005ddb;
	color: #fff;
}
.image_1 {
	margin-right: 0.25rem;
	align-self: center;
	width: 1.13rem;
	height: 0.25rem;
}
.text-wrapper {
	padding: 4.88rem 0 5.75rem;
	color: rgb(255, 255, 255);
	font-size: 1.88rem;
	line-height: 1.75rem;
	white-space: nowrap;
	background: #0355d7 url(../assets/bg.png) right no-repeat;
	/* display:flex; */
	/* justify-content: space-around; */
}
.text_2 {
	color: rgb(0, 0, 0);
	font-size: 1.25rem;
	line-height: 1.19rem;
	white-space: nowrap;
	margin-left: 1.31rem;
}
.text_3,
.text_4 {
	flex: 1;
	margin-left: 0.5rem;
	border: 0;
	outline: none;
}
.text_5 {
	display: block;
	text-align: right;
	margin-right: 2.13rem;
	margin-top: 1.44rem;
	color: rgb(62, 97, 164);
	font-size: 1rem;
	line-height: 1.06rem;
	white-space: nowrap;
}
.text_7 {
	display: block;
	text-align: center;
	margin-top: 1.88rem;
	margin-bottom: 2.9rem;
	align-self: center;
	color: rgb(62, 97, 164);
	font-size: 1rem;
	line-height: 0.94rem;
	white-space: nowrap;
}
.text_8 {
	text-align: center;
	font-size: 0.5rem;
	color:black;
}
.routerlink {
    /* text-decoration: none;  */
	color:black;
}
.image {
	margin-bottom: 0.13rem;
	width: 0.81rem;
	height: 0.81rem;
}
.text {
	margin-left: 0.69rem;
}
.text_1 {
	text-align: left;
	margin-left: 1.31rem;
}
.box_1 {
	height: 77.5vh;
}
.text_111 {
	font-size:1rem;
	margin-top: 1rem;
}
</style>
